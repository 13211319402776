.contact-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.contact-page a svg {
  margin: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  width: 6vh;
  height: 6vh;
}

.contact-page .social-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  margin-top: 2rem;
  width: 90vw;
  max-width: 600px;
  background-color: rgb(228, 228, 228);
  box-shadow: 2px 2px rgb(138, 138, 138);
  border-radius: 1rem 1rem 0 0;
}

.contact-page .contact-panel {
  margin-top: 0;
  width: 90vw;
  max-width: 600px;
  background-color: rgb(228, 228, 228);
  box-shadow: 2px 2px rgb(138, 138, 138);
  border-radius: 0 0 1rem 1rem;
}

.contact-page label {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1.5rem;
  font-weight: bold;
}

.contact-page input {
  margin: 0;
  margin-top: 0.2rem;
  padding: 0.2rem;
  width: 98%;
  font-size: 1.2rem;
  border: 2px solid grey;
  border-radius: 0.5rem;
  outline-color: black;
}

.contact-page textarea {
  margin: 0;
  margin-top: 0.2rem;
  padding: 0.2rem;
  width: 98%;
  font-size: 1.2rem;
  font-family: inherit;
  border: 2px solid grey;
  border-radius: 0.5rem;
  resize: none;
  outline-color: black;
}

.contact-page .button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: -3.7rem;
  padding-right: 1.7rem;
  margin-bottom: 2rem;
}

.contact-page button {
  height: 2rem;
  width: 5rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background-color: black;
  border-radius: 0.4rem;
}
