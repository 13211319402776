ul.NavDropDown {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  list-style: none;
  padding-left: 0;
}

.NavDropDown a {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-grow: 1;
  color: white;
  text-decoration: none;
  height: 10vh;
  font-size: 2rem;
  border-bottom: 1px solid gray;
}

.NavDropDown a:visited {
  color: white;
}

.NavDropDown li:nth-child(1) {
  background-color: rgb(20, 20, 20);
}

.NavDropDown li:nth-child(2) {
  background-color: rgb(40, 40, 40);
}

.NavDropDown li:nth-child(3) {
  background-color: rgb(60, 60, 60);
}
