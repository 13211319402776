.App {
  text-align: center;
  background-color: rgb(236, 236, 236);
  margin: 0;
  padding: 0;
  padding-bottom: 10vh;
  height: auto;
  min-height: 90vh;
  width: 100vw;
  position: relative;
}

.siteWarning {
  color: red;
}
