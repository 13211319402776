.NavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  height: 10vh;
  width: 100vw;
}

.NavBar .links {
  display: flex;
  padding: 0;
  margin: 0;
}

.NavBar .links a {
  padding-left: 1rem;
  font-size: 2rem;
  text-decoration: none;
  color: white;
}

.NavBar .burgerMenu {
  padding: 0.5rem;
  height: 7vh;
  aspect-ratio: 1/1;
}

.NavBar img {
  margin: 1rem;
  height: 6vh;
  aspect-ratio: 1 / 1;
}

.NavBar .links a:visited {
  padding: 0.5rem;
  color: white;
}
